import React, { useState } from "react";
import {Table} from "@myob/myob-widgets";

function AccountsTable({accounts, setAccounts}){

  const [selectedRows, setselectedRows] = useState([]);

  const tableColumns = [
    { key: 'name', description: 'Account name', visible: true, align: 'left' },
    { key: 'number', description: 'Account number', visible: true, align: 'left' },
    { key: 'type', description: 'Account type', visible: true, align: 'left' },
  ];

  const setIdForAccountsData = () => {
    let counter = 1
    accounts.map(
      account => account.id = (counter++).toString()
    )
  }

  setIdForAccountsData()

  const getIdOfSelectedAccount = (id, isSelected) => {
    let selected;
    if (id === null) {
      selected = isSelected ? accounts.map(item => item.id) : []
    } else {
      const exists = selectedRows.includes(id);
      selected = exists ? selectedRows.filter(selectedId => selectedId !== id) : selectedRows.concat(id)
    }
    return selected;
  }

  const matchAccountsToSelectedTableRows = (selectedRows) => {
    return accounts.filter(account => selectedRows.some(selectedId => account.id === selectedId));
  }

  const onRowSelect = (id, isSelected) => {
    let selectedRows = getIdOfSelectedAccount(id, isSelected);
    setselectedRows(selectedRows);
    setAccounts(matchAccountsToSelectedTableRows(selectedRows))
  };

  return(
    <Table
      data={accounts}
      columns={tableColumns}
      onRowSelect={onRowSelect}
      canSelectMultiple
      selectedData={selectedRows}
    />
  )
}

export default AccountsTable
