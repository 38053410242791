import FeedConfirmationView from "../FeedConfirmationView";
import OpenBankingFeedConfirmationView from "../OpenBankingFeedConfirmationView";
import * as _ from "lodash";
import React from "react";
import OpenBankingAccountSummary from "./components/OpenBankingAccountSummary";

const FeedConfirmationResultContainer = ({isFromOpenBanking,
                                           areAllAccountsOnboarded,
                                           accountsNotYetOnboarded,
                                           redirectUrl,
                                           selectedOnboardAccounts
                                         }) => {
  const getOpenBankingSummarySection = () => {
    if (areAllAccountsOnboarded) {
      return <OpenBankingAccountSummary resultStatus={"alreadyOnboarded"}/>
    }
    const unselectedAccounts = accountsNotYetOnboarded.filter(account => !selectedOnboardAccounts.includes(account));
    if (!_.isEmpty(unselectedAccounts)) {
      return <>
        <OpenBankingAccountSummary accounts={selectedOnboardAccounts} resultStatus={"selected"}/>
        <OpenBankingAccountSummary accounts={unselectedAccounts} resultStatus={"unselected"}/>
      </>
    }
    return <OpenBankingAccountSummary accounts={selectedOnboardAccounts} resultStatus={"allSuccess"}/>
  }

  if (!isFromOpenBanking) {
    return (
      <FeedConfirmationView
        accountData={selectedOnboardAccounts}
        redirectUrl={redirectUrl}
      />
    );
  }
  return (
    <OpenBankingFeedConfirmationView
      summarySection={getOpenBankingSummarySection()}
      redirectUrl={redirectUrl}
    />
  );
}

export default FeedConfirmationResultContainer;
