import React, {useContext, useEffect, useState} from "react";
import Loading from "../../common/Loading";
import ErrorView from "../../common/ErrorView";
import {apiContext} from "../../ApiContext";
import SelectAccountsContainer from "./select-accounts/SelectAccountsContainer";
import FeedConfirmationResultContainer from "./feed-confirmation/openbanking/FeedConfirmationResultContainer";

function OnboardFinaliseContainer({ authCode, consentId, onboardingApplicationData, error }) {

  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(error)
  const [feedAccountDetails, setFeedAccountDetails] = useState()
  const [accounts, setAccounts] = useState([])
  const [accountsToOnboard, setAccountsToOnboard] = useState([])
  const [isFeedCreated, setFeedCreated] = useState(false)
  const [isBankFeedLimitExceeded, setBankFeedLimitExceeded] = useState(true)
  const api = useContext(apiContext)

  const areAllAccountsOnboarded = () => {
    return accounts.every(account => account.onboarded);
  }

  const fetchAccountsNotOnboarded = (accounts) => {
    return accounts.filter(account => !account.onboarded);
  }

  const isFromOpenBanking = () => {
    return getDecodedValue("source")?.toLowerCase() === "OpenBanking".toLowerCase();
  }

  const createFeeds = async (selectedAccounts) => {
    setLoading(true);
    setAccountsToOnboard(selectedAccounts);

    try {
      const applicationId = getDecodedValue("applicationId");
      await api.createFeeds(applicationId, selectedAccounts);
      setFeedCreated(true);
    } catch (err) {
      if (isFromOpenBanking()) {
        await api.revokeConsent(getDecodedValue("applicationId"), consentId);
      }
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getFeedAccountDetails = async () => {
      try {
        const data = await api.fetchAccountData(authCode, onboardingApplicationData, consentId);
        setFeedAccountDetails(data);
        setAccounts(data.accounts);
        if ((fetchAccountsNotOnboarded(data.accounts).length <= data.availableFeeds || data.availableFeeds == null) && isFromOpenBanking()) {
          setBankFeedLimitExceeded(false);
          if(fetchAccountsNotOnboarded(data.accounts).length > 0) {
            await createFeeds(fetchAccountsNotOnboarded(data.accounts));
          }
        }
        if (fetchAccountsNotOnboarded(data.accounts).length === 0) {
          await api.revokeConsent(getDecodedValue("applicationId"), consentId)
        }
        setLoading(false);
      } catch (err) {
        if (err.message !== 'Unauthorized') {
          if (getDecodedValue("source")?.toLowerCase() === "OpenBanking".toLowerCase()) {
            await api.revokeConsent(getDecodedValue("applicationId"), consentId);
          }
          setError(true);
          setLoading(false);
        }
      }
    };

    getFeedAccountDetails();
  }, []);

  const atobDecoding = (data) =>{
    return Buffer.from(data, "base64").toString();
  }

  const getDecodedValue = (key) => {
    const decodedAppData = atobDecoding(onboardingApplicationData).split('&');
    const dataPairs = decodedAppData.map(dataPair => dataPair.split('='));
    let pair = dataPairs.find(dataPair => dataPair[0] === key);
    return pair && pair[1];
  }

  const redirectUrl = getDecodedValue("productRedirectUrl")

  const renderError = () => {
    if(isFromOpenBanking()) {
      return <ErrorView type='openBankingFinaliseError' redirectUrl={redirectUrl}/>
    }
    return accountsToOnboard.length > 0 ? <ErrorView type='finalisationFailed' redirectUrl={redirectUrl}/>
      : <ErrorView type='applicationFailed' redirectUrl={redirectUrl}/>
  }

  const renderFeedsOnboardingView = () => {
    return (isFeedCreated || areAllAccountsOnboarded()) ? (
      <FeedConfirmationResultContainer
        isFromOpenBanking={isFromOpenBanking()}
        areAllAccountsOnboarded={areAllAccountsOnboarded()}
        redirectUrl={redirectUrl}
        accountsNotYetOnboarded={fetchAccountsNotOnboarded(accounts)}
        selectedOnboardAccounts={accountsToOnboard}/>)
      :
      (isBankFeedLimitExceeded && <SelectAccountsContainer
        accounts = {fetchAccountsNotOnboarded(accounts)}
        bankfeedLimit = {feedAccountDetails.bankfeedLimit}
        availableFeeds = {feedAccountDetails.availableFeeds}
        productRedirectUrl={redirectUrl}
        subscriptionUpgradeUrl={feedAccountDetails.subscriptionUpgradeUrl}
        onSubmit={createFeeds}
        source={getDecodedValue("source")}
      />)
  }

  return (
    <div>
      {
        isError ? renderError() :
          isLoading ?
            <Loading message={"Fetching your feed info..."}/> :
            renderFeedsOnboardingView()
      }
    </div>
  )
}

export default OnboardFinaliseContainer;
