import ErrorView from "../../common/ErrorView";
import React from "react";

function OpenBankingRedirectErrorView({errorCode: error}) {
  if (error === 'usr_opt_out') {
    return (
      <ErrorView type='openBankingOptOutError' />
    );
  }
  if (error === 'usr_dh_not_listed') {
    return (
      <ErrorView type='openBankingUsrDhNotListedError' />
    );
  }
  return (
    <ErrorView type='openBankingRedirectError' />
  );
}

export default OpenBankingRedirectErrorView;
